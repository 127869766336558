.slideshow {
  overflow: hidden;
  .slide {
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    height:100vh;
    max-height:495px;
    display:flex;
    align-items:center;
    position: relative;
    &:before {
      width:100%;
      height:100%;
      content:" ";
      position: absolute;
      left:0;
      top:0;
      right:0;
      bottom:0;
      opacity: 0.54;
      background-image: linear-gradient(270deg, rgba(0,0,0,0.16) 21%, #000000 93%);
      border: 1px solid #979797;
      z-index: 100;
    }
    .inner-wrap {
      position: relative;
      z-index: 200;
      div {
        max-width:485px;
        padding:35px 0;
        h1 {
          margin-bottom:30px;
        }
        p {
          font-size: 14px;
          color: #FFFFFF;
          line-height: 1.7;
          text-shadow: 0 0 4px rgba(0,0,0,0.50);
          margin-bottom:15px;
        }
      }
    }
  }
}

.subslide-wrapper {
  position: relative;
  .pager {
    position: absolute;
    bottom:10px;
    left:20px;
    z-index: 1000;
    span {
      display: inline-block;
      width:9px;
      height:9px;
      text-indent: 9999px;
      overflow: hidden;
      background: #69747D;
      border-radius: 50%;
      margin-right:5px;
      cursor: pointer;
      &.cycle-pager-active {
        background: #fff;
      }
    }
    &[data-count-items="1"] {
      display: none;
    }
  }
}