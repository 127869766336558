button {
  border:0;
}
.btn {
  position: relative;
  display: inline-block;
  padding:10px 15px;
  background:$orange;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size:12px;
  line-height:1;
  border-radius: 4px;
  max-width:115px;
  width:100%;
  &.large {
    font-size:15px;
    padding:15px 25px;
  }
  &:active {
    top:1px;
  }
  &:hover, &:focus {
    text-decoration: none;
    color: white;
  }
}

a.secondary-btn {
  font-size: 17px;
  color: #FB5C1F;
  font-style: normal;
  font-weight: 400;
  font-family:$title-font-family;
  text-transform: lowercase;
  &:after {
    display: inline-block;
    font-family: FontAwesome;
    content: "\f178";
    display: inline-block;
    padding-left: 7px;
    vertical-align: middle;
    color: #FB5C1F;
    position: relative;
    top:-1px;
  }
}

.back-btn {
  display:inline-block;
}