#mobile-nav-items {
  display: block;
  position: absolute;
  top: 0;
  right: -354px;
  @media(max-width:1040px) {
    right:0;
  }
  max-width: 354px;
  width:100vw;
  bottom: 0;
  height: 100%;
  z-index: 50;
  text-align:left;
  background:#242424;
  padding:0;
  .top {
    padding:25px 35px;
  }
  ul {
    padding:0 35px;
    margin:0;
    li {
      color:#fff;
      font-style: normal;
      font-weight: 400;
      text-transform: uppercase;
      display: block;
      text-align:left;
      font-size: 16px;
      border-bottom: 1px solid rgba(#979797, 0.2);
      font-family:$title-font-family;
      font-style: normal;
      font-weight: 500;
      li {
        padding:5px 0;
      }
      a,span {
        color:#fff;
        display:inline-block;
        line-height: 1.25em;
        padding: 14px 0;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        i {
          display: inline-block;
          margin-left:10px;
          position: relative;
          top:-3px;
        }
      }
      ul.second {
        display: none;
        margin:5px 0 25px;
        padding:0;
        li {
          border-bottom:0;
          display: flex;
          padding:0;
          text-transform: none;
          margin-bottom:10px;
          span {
            padding:0;
            margin-right:8px;
          }
          a {
            padding:0;
            font-size:13px;
            display: inline-block;
          }
        }
      }
      ul.open {
        display: block;
      }
      &.active {
        color: #6593BD;
        span {
          color: #6593BD;
        }
        ul.second {
          display: block;
          li { 
            a {
              color:#fff;
              &.active {
                text-decoration: underline;
              }
            }
          }
        }
        > a {
          color: #6593BD;;
        }
      }
    }
  }
}
