.frm-dialog {
  max-width: 405px;
  width: 80%;
  padding:0;
  margin:0 auto;
  .fancybox-close-small {
   height:76px;
   color:#fff; 
   right:16px;
   svg {
    stroke-width: 3px;
   }
  }
  .top {
    padding:29.5px 30px;
    font-size: 17px;
    color: #FFFFFF;
    line-height: 1;
    background:#69747D;
    h2 {
      color:#fff;
      font-size:17px;
      line-height: 1;
      margin:0;
      @media(max-width:900px) { font-size:16px; }
      @media(max-width:600px) { font-size:15px; }
      @media(max-width:450px) { font-size:14px; }
    }
  }
  .dialog-item-inner {
    padding: 25px 30px;
    form {
      .form-item {
        button.btn {
          margin-top:0;
        }
      }
    }
  }
}
