h1,h2,h3 {
  font-family:$title-font-family;
  font-style: normal;
  font-weight: 500;
}

h1 {
  letter-spacing: 0;
  font-style: normal;
  font-size: 35px;
  color: #FFFFFF;
  line-height: 1.2;
  text-transform: uppercase;
  @media (max-width:900px) { font-size:30px; }
  @media (max-width:600px) { font-size:26px; }
  @media (max-width:450px) { font-size:22px; }
}

h2, h3 {
  font-size: 27px;
  color: #434343;
  line-height: 1.2;
  @media (max-width:900px) { font-size:24px; }
  @media (max-width:600px) { font-size:21px; }
  @media (max-width:450px) { font-size:18px; }
}

