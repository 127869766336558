.listProjects {
  text-align:left;
  display: flex;
  flex-wrap: wrap;
  margin-left:-15px;
  margin-right:-15px;
  li {
    margin-left:15px;
    margin-right:15px;
    margin-bottom:25px;
    width:calc(33% - 30px);
    @media(max-width:900px) { width:calc(50% - 30px); margin-bottom:20px; }
    @media(max-width:550px) { width:calc(100% - 30px); margin-bottom:15px; }
    a.img-wrap {
      width:100%;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      img {
        height:180px;
        opacity: 0;
      }
    }
    span.txt-wrap {
      display: block;
      padding:25px 10px;
      h3 {
        font-size: 24px;
        color: #014A96;
        a {
          color: #014A96;
        }
        @media(max-width:900px) { font-size:22px; }
        @media(max-width:600px) { font-size:20px; }
        @media(max-width:450px) { font-size:18px; }
      }
    }
  }

}

.project-details-body {
  padding-bottom:25px;
}

.project-details-wrap {
  padding:25px 0;
  h2 {
    font-size: 29px;
    color: #0D569E;
    line-height: 1.35;
    @media(max-width:900px) { font-size:26px; }
    @media(max-width:600px) { font-size:23px; }
    @media(max-width:450px) { font-size:20px; }
  }
  .project-details-inner {
    background: #EDF1F2;
    padding:30px;
    ul {
      margin:0 -20px;
      display: flex;
      flex-wrap: wrap;
      li {
        width:calc(33.33% - 40px);
        margin:0 20px;
        border-bottom: 1px solid #D3D3D3;
        font-size: 14px;
        color: #666666;
        line-height: 1.35;
        padding:10px 0;
        @media(max-width:800px) {
          width:calc(50% - 40px);
        }
        @media(max-width:550px) {
          width:calc(100% - 40px);
        }

      }
      &[data-count-items="2"] {
        li {
          width:calc(50% - 40px);
        }
      }
      &[data-count-items="1"] {
        li {
          width:calc(100% - 40px);
        }
      }
    }
  }
}

.related-items-wrap {
  padding:25px 0 0;
  h2 {
    font-size: 29px;
    color: #0D569E;
    line-height: 1.35;
    @media(max-width:900px) { font-size:26px; }
    @media(max-width:600px) { font-size:23px; }
    @media(max-width:450px) { font-size:20px; }
  }
  ul.listProjects {
    margin-bottom:0;
    li {
      @media(min-width:900px) { 
        margin-bottom:0;
        span.txt-wrap {
          padding-bottom:0;
        }
      }
    }
  }
}