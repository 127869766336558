.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
  height: 0;
  margin:30px auto 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}