.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}

.page-body-mid {
  padding:75px 0;
  text-align:center;
  @media(max-width:900px) { padding:60px 0; }
  @media(max-width:600px) { padding:50px 0; }
  @media(max-width:450px) { padding:40px 0; }
  h2 {
    font-size: 27px;
    color: #434343;
    line-height: 1.35;
    margin-bottom:18px;
    @media(max-width:900px) { font-size: 24px; }
    @media(max-width:600px) { font-size: 21px; }
    @media(max-width:450px) { font-size: 18px; }
  }
  p {
    font-size: 14px;
    color: #434343;
    line-height: 1.5;
    max-width:610px;
    margin:0 auto;
    font-style: normal;
    font-weight: 300;
  }
  &.general-content-wrap {
    text-align:left;
    margin:0 auto;
    padding-top:45px;
    @media(max-width:900px) { padding-top:35px; }
    @media(max-width:600px) { padding-top:25px; }
    @media(max-width:450px) { padding-top:20px; }
    .general-txt {
      h2,h3,h4 {
        font-size:18px;
        margin:10px 0;
      }
      h2 {
        margin-top:35px;
      }
      h3 {
        font-size:16px;
      }
      p {
        max-width: none;
        margin-top:1.5em;
        &:nth-child(1) {
          margin-top:0;
          font-size: 18px;
          color: #0D569E;
          line-height: 1.3;
          font-style: normal;
          font-weight: 500;
          @media(max-width:900px) { font-size: 17px; }
          @media(max-width:600px) { font-size: 16px; }
          @media(max-width:450px) { font-size: 15px; }
        }
      }
      ul {
        list-style-type: disc;
        margin:1.5em 20px;
        li {
          margin:5px 0;
          padding:0 10px;
        }
      }
    }
    .row {
      margin:0;
      @media(max-width:900px) {
        flex-direction: column;
      }
      .col {
        width:50%;
        flex-basis: auto;
        flex-grow: 1;
        margin:0;
        padding:0;
        &:nth-child(1) {
          padding-right:50px;
        }
        @media(max-width:900px) {
          width:100%;
          &:nth-child(1) {
            padding-right:0;
            margin-bottom: 35px;
          }
          &.content-imgs {
            img {
              width:100%;
            }
          } 
        }
      }
    }
  }
}


article.page-body-btm {
  padding:55px 0;
  &.alt {
    background:#EDF1F2;
  }
  ul.featuredItems {
    display: flex;
    margin:0 -15px;
    @media(max-width:900px) {
      flex-direction: column;
      margin:0;
    }
    li {
      background: #FFFFFF;
      margin:0 15px;
      padding:25px;
      text-align:center;
      width:calc(33.33% - 30px);
      @media(max-width:900px) {
        display: flex;
        width:calc(100% - 30px);
        &:nth-child(2) {
          margin-top:35px;
          margin-bottom:35px;
        }
      }
      @media(max-width:400px) {
        flex-direction: column;
      }
      .img-wrap {
        height:100px;
        display:flex;
        justify-content:center;
        align-items:center;
        @media(max-width:900px) {
          height:auto;
          width:180px;
        }
        @media(max-width:600px) {
          width:100px;
        }
        @media(max-width:400px) {
          text-align:center;
          margin:0 auto 25px;
        }
      }
      .txt-wrap {
        h4 {
          color:$orange;
          font-size: 20px;
          font-family:$title-font-family;
          margin:15px 0;

        }
        @media(max-width:900px) {
          width:calc(100% - 100px);
          padding-left:35px;
          text-align: left;
          h4 {
            margin-top:0;
          }
        }
        @media(max-width:600px) {
          width:calc(100% - 100px);
        }
        @media(max-width:400px) {
          padding-left:0;
          width:100%;
        }
      }
    }
  }
}