.tab-wrap {
  text-align:center;
  margin-top:55px;
  span {
    display: inline-block;
    @media(max-width:450px) {
      display: block;
    }
    background: #EAEFF5;
    font-size: 12px;
    color: #232323;
    padding:15px 35px;
    line-height: 1;
    font-family:$title-font-family;
    text-align: center;
    text-transform: uppercase;
    border-left:3.5px #fff solid;
    border-right:3.5px #fff solid;
    border-top:1.5px #fff solid;
    border-bottom:1.5px #fff solid;
    cursor: pointer;
    position: relative;
    font-style: normal;
    font-weight: 600;
    &:active {
      top:1px;
    }
    &.selected {
      background:#DBE0E5;
      &:after {
        content: " ";
        width: 0; 
        height: 0; 
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-top: 11px solid #DBE0E5;
        position: absolute;
        bottom:- 10px;
        left:calc(50% - 11px);
        @media(max-width:450px) {
          display: none;
        }
      }
    }
  }
}

.tab-wrap-content-wrap {
  margin-bottom:25px;
  .tab-wrap-content-inner {
    max-width:980px;
    margin:0 auto;
    ul.main_featured_items {
      margin: 55px 0 30px;
  }
  }
}