.page-body-mid.general-content-wrap .row {
  .contact-details {
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    .row {
      padding:40px;
      display: flex;
      flex-wrap: wrap;
      @media(max-width:900px) {
        flex-direction: row;
      }
      @media(max-width:500px) {
        flex-direction: column;
      }
      @media(max-width:300px) {
        padding:25px;
      }
      .col-full-width {
        width:100%;
        flex-grow: 1;
        margin-bottom:5px;
        h3 {
          font-size: 14px;
          color: #FE5000;
          letter-spacing: 0;
          line-height: 1.25;
          text-transform: uppercase;
          font-style: normal;
          font-weight: 700;
        }
      }
      .col {
        width:50%;
        @media(max-width:900px) {
          width: 50%;
        }
        @media(max-width:500px) {
          width: 100%;
          &:nth-child(3) {
            margin-top:15px;
          }
        }
        padding:0;
        margin:0;
        flex-grow: 0;
        flex-basis: auto;
        font-size: 12px;
        color: #424242;
        letter-spacing: 0;
        line-height: 1.5;
        font-style: normal;
        font-weight: 500;
        span {
          display: block;
          font-size: 12px;
          color: #000000;
          line-height: 1.5;
          text-transform: uppercase;
          margin:0 0 5px;
          i {
            display: inline-block;
            width:22px;
            text-align: center;
            color: #FE5000;
          }
          a {
            color: #000000;
          }
        }
      }
    }
  }
}