ul.homepage-featured {
  display: flex;
  margin-left:-15px;
  margin-right:-15px;
  margin-bottom:0;
  @media(max-width:900px) {
    flex-direction: column;
    width: 100%;
    margin-left:0;
    margin-right:0;

  }
  li {
    margin-left:15px;
    margin-right:15px;
    text-align:left;
    width: 33%;
    @media(max-width:900px) {
      display: flex;
      width: 100%;
      margin-left:0;
      margin-right:0;
      &:nth-child(2) {
        margin-top:35px;
        margin-bottom:35px;
      }
    }
    @media(max-width:460px) {
      flex-direction: column;
    }
    a.img-wrap {
      height:180px;
      display: inline-block;
      overflow: hidden;
      width:100%;
      background-repeat: no-repeat;
      background-size:cover;
      background-position: center;
      img {
        opacity:0;
      }
      @media(max-width:900px) {
        width:280px;
        height:auto;
        img {
          opacity: 0;
        }
      }
      @media(max-width:700px) {
        width:200px;
      }
      @media(max-width:460px) {
        width:100%;
      }
    }
    .txt-wrap {
      padding:20px 15px 0;
      @media(max-width:900px) {
        width:calc(100% - 280px);
        padding-left:35px;
      }
      @media(max-width:700px) {
        width:calc(100% - 200px);
      }
      @media(max-width:460px) {
        width:100%;
        padding-left:15px;
      }
      h3 {
        font-size: 24px;
        color: #014A96;
        @media(max-width:600px) { font-size:22px; }
        @media(max-width:460px) { font-size:19px; }
      }
      p {
        font-size: 13px;
        color: #838383;
        line-height: 1.5;
      }
      span {

      }
    }
  }
}
