
$title-font-family:	"futura-pt";
$standard-font-family: "proxima-nova";

$container: 1050;

$yellow: #CA9C64;
$gold: $yellow;
$red: #B6051A;
$darkblue: #206788;
$blue: #33A0C3;
$orange: #FB5C1F;
$textshadow: 0px 0px 4px rgba(0, 0, 0, 0.67);

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;

$imgpath: '../../../../assets/img/';
