.navbar {
  padding:33px 0;
  .inner-wrap{
    .row {
      margin:0;
      display: flex;
      align-items:center;
      flex-wrap: nowrap;
      .col {
        padding:0;
        width:auto;
        flex-basis: auto;
        &:nth-child(2) {
          flex-grow: 1;
          text-align: right;
          @media(max-width:900px) {
            display: none;
          }
          ul {
            display: inline-block;
            margin:0;
            li {
              display: inline-block;
              font-size: 13px;
              color: #3C3C3A;
              letter-spacing: 1.08px;
              font-family:$title-font-family;
              font-style: normal;
              font-weight: 500;
              text-transform: uppercase;
              position: relative;
              margin-left:30px;
              &:nth-child(1) {
                margin-left:0;
              }
              a {
                color: #3C3C3A;
                &:hover {
                  text-decoration: none;
                  color:$orange;
                }
                &:active,
                &:focus {
                  text-decoration: none;
                }
              }
              &.active {
                border-bottom:3px solid $orange;
                a {
                  color:#014A96;
                }
              }
              &:nth-child(1) {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Mobile Button */
#mobile-burger-btn {
  cursor: pointer;
  display:none;
  height: 38px;
  margin:0;
  text-align:center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  border-radius: 0;
  i {
    font-size:28px;
    position: relative;
    top:5px;
    &:nth-child(2) {
      display:none;
    }
  }
  @media(max-width:900px) {
    display: inline-block;
    position: absolute;
    right:20px;
    top:20px;
    z-index: 9999;
  }
  &.menu-open {
    //right:calc(15.625em + 20px);
    i {
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}
