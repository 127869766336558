footer {
  background:#242424;
  color:#E7E7E7;
  padding-bottom:30px;

  a {
    color:#E7E7E7;
    &:hover {
      color:#E7E7E7;
    }
  }
  h4 {
    font-size: 14px;
    color: #6593BD;
    line-height: 1;
    font-family:$title-font-family;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom:10px;
  }
  img {
    margin-bottom:15px;
  }
  ul.sitemap {
    columns: 2;
    max-width:260px;
    @media(max-width:900px) {
      margin:0 auto;
      max-width:100%;
    }
    @media(max-width:400px) {
      columns: 1;
    }
    li {
      font-size: 12px;
      color: #E7E7E7;
      line-height: 1.35;
      font-style: normal;
      font-weight: 300;
      margin-bottom:10px;
    }
  }
  .top {
    padding:60px 0;
    .inner-wrap {
      display: flex;
      justify-content: space-between;
      @media(max-width:900px) {
        flex-direction: column;
      }
      .footer-col {
        width:33%;
        margin-left: 15px;
        margin-right: 15px;

        p {
          max-width:285px;
        }
        @media(max-width:900px) {
          width:50%;
          margin-bottom:35px;
          text-align: left;
          margin-left:0;
          margin-right:0;
          p {
            max-width:100%;
          }
        }
        @media(max-width:500px) {
          width:100%;
          margin-bottom:35px;
          text-align: left;
          margin-left:0;
          margin-right:0;
        }
      }
    }
  }
  .btm {
    border-top:1px #424242 solid;
    padding:30px 0;
    a {
      text-decoration: underline;

    }
  }
}
