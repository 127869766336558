.map {
  height:296px;
  margin:0;
  width:100%;
  display: block;
  .infoMarker {
    text-align:left;
    color:#333;
  }
}
